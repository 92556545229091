var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "docsify-editor" },
    [
      !_vm.hideToolBar
        ? _c("Toolbar", {
            staticClass: "docsify-editor-toolbar",
            attrs: {
              editor: _vm.editor,
              defaultConfig: _vm.toolbarConfig,
              mode: _vm.editorMode,
            },
          })
        : _vm._e(),
      _c("Editor", {
        staticClass: "docsify-editor-engine",
        attrs: { defaultConfig: _vm.editorConfig, mode: _vm.editorMode },
        on: {
          onCreated: _vm.onCreated,
          onChange: _vm.onChange,
          customPaste: (editor, $event) => _vm.customPaste(editor, $event),
        },
        model: {
          value: _vm.html,
          callback: function ($$v) {
            _vm.html = $$v
          },
          expression: "html",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }